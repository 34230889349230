import _ from 'lodash';

const initialState = {
  manualVerifications: [],
};

const resetState = {
  manualVerifications: [],
};


const AdminWorkReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_ADMIN_WORK':
    {
      return resetState;
    }

    case 'RESET_STATE_ADMIN_WORK':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'SYNC_WORK_QUEUE_VERIFICATIONS':
    {
      console.warn("SYNC_WORK_QUEUE_VERIFICATIONS");
      const clone = Object.assign({}, state);
      //clone.manualVerifications = action.data;

      for (let y = 0; y < action.data.length; y++) {
        let id = action.data[y].id;
        let businessDataRegion = action.data[y].businessDataRegion;
        let businessId = action.data[y].businessId;
        let customerNotes = action.data[y].customerNotes;
        let workNotes = action.data[y].workNotes;
        let verificationType = action.data[y].verificationType;
        let status = action.data[y].status;
        let identityId = action.data[y].identityId;
        let createdAt = action.data[y].createdAt;
        let modifiedAt = action.data[y].modifiedAt;

        let foundRecord = false;

        for (let x = 0; x < clone.manualVerifications.length; x++) {
          if (clone.manualVerifications[x].id === id) {
            foundRecord = true;

            clone.manualVerifications[x].businessDataRegion = businessDataRegion;
            clone.manualVerifications[x].businessId = businessId;
            clone.manualVerifications[x].status = status;
            clone.manualVerifications[x].customerNotes = customerNotes;
            clone.manualVerifications[x].workNotes = workNotes;
            clone.manualVerifications[x].verificationType = verificationType;
            clone.manualVerifications[x].status = status;
            clone.manualVerifications[x].identityId = identityId;
            clone.manualVerifications[x].createdAt = createdAt;
            clone.manualVerifications[x].modifiedAt = modifiedAt;

          }
        }

        if (foundRecord === false) {
          let newRecord = {
            businessDataRegion,
            businessId,
            createdAt,
            customerNotes,
            data: {},
            id,
            identityId,
            modifiedAt,
            status,
            verificationType,
            workNotes,
            _id: id,
          }
          clone.manualVerifications.push(newRecord);
        }

      }

      return clone;
    }
    
    case 'STORE_WORK_QUEUE_VERIFICATION_DATA':
    {
      const clone = Object.assign({}, state);
      let id = action.data.workQueueItemId;
      let workItemData = action.data.workItemData;
      let businessItemData = action.data.businessItemData;

      console.warn("STORE_WORK_QUEUE_VERIFICATION_DATA");
      console.warn(action.data );
      console.warn(id);
      console.warn(workItemData);
      console.warn(businessItemData);

      let foundRecord = false;

      for (let x = 0; x < clone.manualVerifications.length; x++) {
        if (clone.manualVerifications[x].id === id) {
          foundRecord = true;

          clone.manualVerifications[x].workNotes = workItemData.workNotes;
          clone.manualVerifications[x].customerNotes = workItemData.customerNotes;
          clone.manualVerifications[x].status = workItemData.status;

          console.log("match on id");
          //we have the correct record to update.
          //Verify the update

          console.log(`businessId: ${clone.manualVerifications[x].businessId} === ${workItemData.businessId}`);
          console.log(`identityId: ${clone.manualVerifications[x].identityId} === ${workItemData.identityId}`);
          console.log(`type: ${clone.manualVerifications[x].verificationType} === ${workItemData.verificationType}`);
          if (clone.manualVerifications[x].businessId === workItemData.businessId &&
            clone.manualVerifications[x].identityId === workItemData.identityId &&
            clone.manualVerifications[x].verificationType === workItemData.verificationType) {
            //Ok to update records
            //console.log("Update data set");
            if (businessItemData === undefined || businessItemData === null || businessItemData === '' || businessItemData === "" || businessItemData === []) {
              console.log("businessItemData is not set, skipping overwrite");
            } else {
              clone.manualVerifications[x].data = businessItemData;
            }
            

          }
        }
      }

      if (foundRecord === false) {
        let newRecord = workItemData;
        newRecord['data'] = businessItemData;
        clone.manualVerifications.push(newRecord);
      }



      return clone;
    }


    /************************************************************************
    ***** NEW REDUX ACTIONS *************************************************
    *************************************************************************/

    case 'REDUX_UPDATE_ADMINWORK':
    {
      const clone = Object.assign({}, state);
      console.log('ADMIN WORK - REDUX_UPDATE_ADMINWORK');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z < updateRecords.length; z++) {
        console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      console.log(clone); 
      return clone;
    }


    
    default:
      return state;
  }
};

export default AdminWorkReducer;
