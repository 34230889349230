import _ from 'lodash';

const initialState = {
  items: [],
    
};

const resetState = {
  items: [],
};

  
  const InteractionsReducer = (state = initialState, action) => {
    switch (action.type) {

      case 'CLEAR_STATE_INTERACTIONS':
      {
        return resetState;
      }

      case 'RESET_STATE_INTERACTIONS':
      {
        const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
      }
  
      case 'ADD_INTERACTION':
      {
        //ADD INTERACTION FROM DATA REFRESH
        const clone = Object.assign({}, state);
        
        return clone;
      }

      case 'UPDATE_INTERACTIONS':
      {
        //MERGE ALL THE INTERACTIONS WITH AN UPDATE
        const clone = Object.assign({}, state);
        
        return clone;
      }

      

      case 'DELETE_INTERACTION':
      {
        //DELETE FROM THE USERS ACCOUNT
        const clone = Object.assign({}, state);
        
        return clone;
      }
  
      case 'REMOVE_ALL_INTERACTIONS':
      {
        //CLEAR OUT ALL LOCAL DATA
        const clone = Object.assign({}, state);
        
        return clone;
      }

      case 'HIDE_THIS_BUSINESS_INTERACTIONS':
      {
        //Low priority
        const clone = Object.assign({}, state);
        
        return clone;
      }

      case 'SHOW_HIDDEN_BUSINESS_INTERACTIONS':
      {
        //Low priority
        const clone = Object.assign({}, state);
        
        return clone;
      }
  
  
      default:
        return state;
    }
  };
  
  export default InteractionsReducer;
  