/** *** BUSINESS IDENTITIES SETUP ***** */
import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  identities: {
    businesses: [],
  },

};

const resetState = {
  identities: {
    businesses: [],
  },
};


const BusinessIdentitiesReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_BUSINESS_IDENTITIES':
    {
      return resetState;
    }

    case 'RESET_STATE_BUSINESS_IDENTITIES':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }


    
    case 'CANCEL_BUSINESS_VERIFICATION':
    {
      const clone = Object.assign({}, state);

      console.warn('CANCEL_BUSINESS_VERIFICATION');
      console.warn(action);

      let businessId = '';
      try {
        businessId = action.data.businessId;
      } catch (e) {
        businessId = '';
      }
      if (businessId === undefined) {
        businessId = '';
      }

      let identityId = '';
      try {
        identityId = action.data.identity._id;
      } catch (e) {
        identityId = '';
      }
      if (identityId === undefined) {
        identityId = '';
      }

      for (let z = 0; z < clone.identities.businesses.length; z++) {
        if (clone.identities.businesses[z]._id === businessId) {

          for (let y = 0; y < clone.identities.businesses[z].businessIdentities.length; y++) {
            if (clone.identities.businesses[z].businessIdentities[y]._id === identityId) {
              clone.identities.businesses[z].businessIdentities[y].awaitingVerification = false;
            }
          }
        }
      } 
      return clone;
    }



    case 'UPDATE_BUSINESS_IDENTITIES_LOCAL_INTERACTIONS':
    {
      const clone = Object.assign({}, state);
      //console.log('UPDATE_BUSINESS_IDENTITIES_LOCAL_INTERACTIONS');
      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      //console.log(`Index of Business: ${indexOfBusiness}`);
 
      let minutesDelay = 5;
      let timeNow = Date.now();

      let tokenExpiryTimestamp = null;

      let localInteractionsExist = false;
      let localInteractionsCount = 0;
      try {
        localInteractionsCount = clone.identities.businesses[indexOfBusiness].localInteractions.length;
      } catch(e) {}
      if (localInteractionsCount > 0) {
        localInteractionsExist = true;
      }

      if (localInteractionsExist === true) {
        //DECREMENT THROUGH ARRAY REMOVING ITEMS THAT ARE PAST EXPIRY
        for (let x = clone.identities.businesses[indexOfBusiness].localInteractions.length; x >= 0; x--) {
          tokenExpiryTimestamp = null;
          try {
            tokenExpiryTimestamp = clone.identities.businesses[indexOfBusiness].localInteractions[x].transactionData.tokenExpiryTimestamp;
          } catch (e) {}

          //Add Microseconds to expirySeconds
          tokenExpiryTimestamp = tokenExpiryTimestamp * 1000;
          
          //console.log(`${timeNow} > ${tokenExpiryTimestamp}`);

          if (timeNow > tokenExpiryTimestamp) {
            //console.log("set this transaction to false");
            try {
              clone.identities.businesses[indexOfBusiness].localInteractions[x].status = false;
            } catch (e) {}
          }

          var tokenExpiryTimestampDateObject = new Date(tokenExpiryTimestamp);

          //Any transactions that are 5 minutes past the expiry should be removed
          var tokenRemovalDateTime = new Date(tokenExpiryTimestampDateObject.getTime() + minutesDelay*60000);



          console.log(`${timeNow} > ${tokenRemovalDateTime}`);
          if (timeNow > tokenRemovalDateTime) {
            console.log("remove this transaction - it is too old");
            try {
              clone.identities.businesses[indexOfBusiness].localInteractions.splice(x, 1);
            } catch (e) {}
          }


        }
      }
     
      return clone;
    }




    // Customer has requested a message from the business (allow the response)
    case 'SET_CUSTOMER_CONNECT_USER_MESSAGE_TO_BUSINESS':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_CUSTOMER_CONNECT_USER_MESSAGE_TO_BUSINESS');

      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      //console.log(`Index of Business: ${indexOfBusiness}`);

      //console.log(action.data.transactionId);

      const indexOfTransaction = clone.identities.businesses[indexOfBusiness].localInteractions.findIndex(int => int.transactionId === action.data.transactionId);
      //console.log(`Index of Business transaction: ${indexOfTransaction}`);

      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions.length);
      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions[0]);
      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions[0].status);
      //console.log(JSON.stringify(clone.identities.businesses[indexOfBusiness].localInteractions[0]));
      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions[0].interactionShortMessageRequest);
      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions[indexOfTransaction].status);
      //console.log(clone.identities.businesses[indexOfBusiness].localInteractions[indexOfTransaction].interactionShortMessageRequest);

      try {
        clone.identities.businesses[indexOfBusiness].localInteractions[indexOfTransaction].interactionShortMessageRequest = true;
      } catch (e) {
        //console.log('Failed to set interactionShortMessageRequest');
        //console.log(e);
      }

      clone.identities.businesses[indexOfBusiness].localSelectedInteraction = undefined;

      return clone;
    }




    //Set the business profile
    case 'SET_CUSTOMER_CONNECT_INTERACTION_BUSINESS_PROFILE':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_CUSTOMER_CONNECT_INTERACTION_BUSINESS_PROFILE');

      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      //console.log(`Index of Business: ${indexOfBusiness}`);

      try {
        clone.identities.businesses[indexOfBusiness].localSelectedBusinessProfile = action.data.profileId;
      } catch (e) {
        //console.log('Failed to set the business profile');
        //console.log(e);
      }

      return clone;
    }

    //Update the status of the customer connect interactions locally
    case 'UPDATE_CUSTOMER_CONNECT_INTERACTIONS_STATUS':
    {
      const clone = Object.assign({}, state);
      //console.log('UPDATE_CUSTOMER_CONNECT_INTERACTIONS_STATUS');

      try {
        for (let y = 0; y < clone.identities.businesses.length; y++) {
          
          try {
            let timestampNowInSeconds = Date.now()/1000;
            for (let x = 0; x < clone.identities.businesses[y].localInteractions.length; x++) {
              if (timestampNowInSeconds > clone.identities.businesses[y].localInteractions[x].transactionData.tokenExpiryTimestamp) {
                //console.log("This has expired");
                //console.log(clone.identities.businesses[y].localInteractions[x].transactionData.tokenExpiryTimestamp);
                clone.identities.businesses[y].localInteractions[x].status = false;
              } else {
                clone.identities.businesses[y].localInteractions[x].status = true;    //testing write 
              }
            }
            
          } catch (e) {
            //console.log('Failed to update local interactions');
            //console.log(e);
          }
          
        }
        
      } catch (e) {
        //console.log('Failed to traverse businesses');
        //console.log(e);
      }

      return clone;
    }


    








    // Set the UI for the active business to "NEW" to show a fresh UI Customer Connection
    case 'SET_CUSTOMER_CONNECT_INTERACTION_NEW':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_CUSTOMER_CONNECT_INTERACTION_NEW');

      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      //console.log(`Index of Business: ${indexOfBusiness}`);

      try {
        clone.identities.businesses[indexOfBusiness].localSelectedInteraction = undefined;
      } catch (e) {
        //console.log('Failed to set the customer interaction');
        //console.log(e);
      }




      //ORIGNAL CODE

      let pathLocal = false;
      let pathLocalConnect = false;
      
      try {
        const path = `identities.businesses[${indexOfBusiness}].local`;
        pathLocal = _.get(clone, path, false);
      } catch (e) {
        //console.log('local path failed');
        //console.log(e);
      }

      if (pathLocal === false) {
        //We can add it directly as it hasn't been setup.
        try {
          clone.identities.businesses[indexOfBusiness].local = {
            connect: {
              selectedInteraction: '',
            },
          };
        } catch (e) {
          //console.log('local path creation failed');
          //console.log(e);
        }
      } else {
        try {
          const path = `identities.businesses[${indexOfBusiness}].local.connect`;
          pathLocalConnect = _.get(clone, path, false);
          if (pathLocalConnect === false) {
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                selectedInteraction: '',
              };
            } catch (e) {
              //console.log('local path creation failed');
              //console.log(e);
            }
          } else {
            try {
              clone.identities.businesses[indexOfBusiness].local.connect.selectedInteraction = '';
            } catch (e) {
              //console.log('local path creation update failed');
              //console.log(e);
            }

            //Clear out the existing data (NEW UNCONFIRMED IF BEST STRATEGY)
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                activeInteractionsPhone: {},
              };
            } catch (e) { console.log(e); }
          
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                activeInteractionsEmail: {},
              };
            } catch (e) { console.log(e); }
              



          }
        } catch (e) {

        }

      }

      return clone;
    }


    // Set the UI for the active business to "LOAD" an existing Customer Connection
    case 'SET_CUSTOMER_CONNECT_INTERACTION_LOAD':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_CUSTOMER_CONNECT_INTERACTION_LOAD');
      //console.log(action);
      //console.log(`transactionId lookup: ${action.data.transactionId}`);

      //data has the transaction id we are looking to load


      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      //console.log(`Index of Business: ${indexOfBusiness}`);

      try {
        clone.identities.businesses[indexOfBusiness].localSelectedInteraction = action.data.transactionId;
      } catch (e) {
        //console.log('Failed to set the customer interaction');
        //console.log(e);
      }













      //ORIGINAL CODE






      let pathLocal = false;
      let pathLocalConnect = false;
      //console.log(`Index of Business: ${indexOfBusiness}`);

      //Find the record we want to load

      const indexOfTransactionId = clone.identities.businesses[indexOfBusiness].local.connect.interactions.findIndex(interaction => interaction.transactionId === action.data.transactionId);
      //console.log(`Index of Interaction: ${indexOfTransactionId}`);
      
      try {
        clone.identities.businesses[indexOfBusiness].local.connect.activeInteractionsPhone = clone.identities.businesses[indexOfBusiness].local.connect.interactions[indexOfTransactionId];
      } catch (e) {
        //console.log('Unable to set the active interaction from load');
        //console.log(e);
      }

      //console.log(clone.identities.businesses[indexOfBusiness].local.connect.interactions[indexOfTransactionId]);

      clone.identities.businesses[indexOfBusiness].local.connect.selectedInteraction = action.data.transactionId;

      return clone;
    }
    





    case 'SET_CUSTOMER_CONNECT_INTERACTION_RESULT':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_CUSTOMER_CONNECT_INTERACTION_RESULT');
      console.warn(action.customerConnectResult);

      /* DATA STRUCTURE
        {
          transactionId: interactionBusinessTemplate.connectTransactionId,
          businessId: interactionBusinessTemplate.businessId,
          businessProfileId: interactionBusinessTemplate.businessProfileId,
          businessUserId: interactionBusinessTemplate.businessUserId,
          transactionData: {
            tokenId: interactionBusinessTemplate.tokenId,
            businessInteractionIntentType: interactionBusinessTemplate.businessInteractionIntentType,
            businessInteractionIntentTime: interactionBusinessTemplate.businessInteractionIntentTime,
            customerContactIdentityMasked: interactionBusinessTemplate.customerContactIdentityMasked,
            customerContactIdentityType: interactionBusinessTemplate.customerContactIdentityType,
            customerContactIdentityDataType: interactionBusinessTemplate.customerContactIdentityDataType,

            tokenGenerationTimestamp: interactionBusinessTemplate.tokenGenerationTimestamp,
            tokenExpiryTimestamp: interactionBusinessTemplate.tokenExpiryTimestamp,
            tokenExpiryInWords: interactionBusinessTemplate.tokenExpiryInWords,
          }
        }
      */

      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.customerConnectResult.businessId);
      let pathLocalInteractions = false;
      //console.log(`Index of Business: ${indexOfBusiness}`);

      ////////////////////////////////////////////
      // WRITE INTERACTION LIST (For sidebar)
      ////////////////////////////////////////////

      try {
        const path = `identities.businesses[${indexOfBusiness}].localInteractions`;
        pathLocalInteractions = _.get(clone, path, false);
      } catch (e) {
        //console.log('local path failed');
        //console.log(e);
      }
      //console.log("pathLocalInteractions");
      //console.log(pathLocalInteractions);
      if (pathLocalInteractions === false) {
        // Write to the local interactions array
        try {
          clone.identities.businesses[indexOfBusiness].localInteractions = [action.customerConnectResult];
        } catch (e) {
          //console.log('Error creating local interactions');
          //console.log(e);
        }
      } else {
        try {
          clone.identities.businesses[indexOfBusiness].localInteractions.push(action.customerConnectResult);
        } catch (e) {
          //console.log('Error writing to local interactions');
          //console.log(e);
        }
      }

      ////////////////////////////////////////////
      // WRITE CONNECT INTERACTION (For displaying on the page)
      ////////////////////////////////////////////

      






      //ORIGINAL CODE BELOW


      let pathLocal = false;
      let pathLocalConnect = false;

      try {
        const path = `identities.businesses[${indexOfBusiness}].local`;
        pathLocal = _.get(clone, path, false);
      } catch (e) {
        //console.log('local path failed');
        //console.log(e);
      }

      if (pathLocal === false) {
        //We can add it directly as it hasn't been setup.
        try {
          clone.identities.businesses[indexOfBusiness].local = {
            connect: {
              interactions: [action.customerConnectResult],
            },
          };
        } catch (e) {
          //console.log('local path creation failed');
          //console.log(e);
        }

        try {
          if (action.customerConnectResult.transactionData.businessInteractionIntentType === "phone") {
            //console.log("write | phone interaction (s)");
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                activeInteractionsPhone: action.customerConnectResult,
              };
            } catch (e) { console.log(e); }
          } 
        } catch (e) {}
        
        try {
          if (action.customerConnectResult.transactionData.businessInteractionIntentType === "email") {
            //console.log("write | phone interaction (s)");
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                activeInteractionsEmail: action.customerConnectResult,
              };
            } catch (e) { console.log(e); }
          } 
        } catch (e) {}
        


      } else {
        try {
          const path = `identities.businesses[${indexOfBusiness}].local.connect`;
          pathLocalConnect = _.get(clone, path, false);
          if (pathLocalConnect === false) {
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                interactions: [action.customerConnectResult],
              };
            } catch (e) {
              //console.log('local path creation failed');
              //console.log(e);
            }

            try {
              if (action.customerConnectResult.transactionData.businessInteractionIntentType === "phone") {
                //console.log("write | phone interaction");
                try {
                  clone.identities.businesses[indexOfBusiness].local.connect.activeInteractionsPhone = action.customerConnectResult;
                } catch (e) { console.log(e); }
              }
            } catch (e) {}
            
            try {
              if (action.customerConnectResult.transactionData.businessInteractionIntentType === "email") {
                //console.log("write | email interaction");
                try {
                  clone.identities.businesses[indexOfBusiness].local.connect.activeInteractionsEmail = action.customerConnectResult;
                } catch (e) { console.log(e); }
              }
            } catch (e) {}
            


          } else {
            let interactionsArray = false;
            //get the current array
            try {
              interactionsArray = _.get(clone, `identities.businesses[${indexOfBusiness}].local.connect.interactions`, false);
            } catch (e) {}
            if (interactionsArray === false) {
              interactionsArray = [];
            } 
            //add to the array
            interactionsArray.push(action.customerConnectResult);
            //push back overtop
            clone.identities.businesses[indexOfBusiness].local.connect.interactions = interactionsArray;

            //console.log("Check interaction type and update the relevant local object.");

            try {
              if (action.customerConnectResult.transactionData.businessInteractionIntentType.toString() === "phone") {
                //console.log("write | phone interaction (2)");
                clone.identities.businesses[indexOfBusiness].local.connect.activeInteractionsPhone = action.customerConnectResult;
              }
            } catch (e) {}
            try {
              if (action.customerConnectResult.transactionData.businessInteractionIntentType.toString() === "email") {
                //console.log("write | email interaction (2)");
                clone.identities.businesses[indexOfBusiness].local.connect.activeInteractionsEmail = action.customerConnectResult;
              }
            } catch (e) {}
            

          }
        } catch (e) {
          //console.log('local path failed (2)');
          //console.log(e);
        }

      }

      return clone;
    }






    case 'SYNC_BUSINESS_REPORTING_LOGS':
    {
      const clone = Object.assign({}, state);
      console.warn('Reducer | SYNC_BUSINESS_REPORTING_LOGS');
      console.warn(action);
      const businessId = action.data.businessId;
      if (businessId !== null) {
        for (let x = 0; x < clone.identities.businesses.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            clone.identities.businesses[x].businessReportingLogs = action.data.logEntries;
          }
        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }




    case 'SYNC_DATA_BUSINESS_RESULTS':
    {
      const clone = Object.assign({}, state);

      const businessId = action.businesses._id;
      if (businessId !== null) {
        for (let x = 0; x < clone.identities.businesses.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            clone.identities.businesses[x].businessAdministrators = action.businesses.businessAdministrators;
            clone.identities.businesses[x].businessContacts = action.businesses.businessContacts;
            clone.identities.businesses[x].businessIdentities = action.businesses.businessIdentities;
            clone.identities.businesses[x].businessIdentityProfiles = action.businesses.businessIdentityProfiles;
            clone.identities.businesses[x].subscriptions = action.businesses.subscriptions;
            clone.identities.businesses[x].businessProfile = action.businesses.businessProfile;
            try {
              clone.identities.businesses[x].accessManagement = action.businesses.accessManagement;
            } catch (e) {}
          }
        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }
    





    case 'BUSINESS_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS - BUSINESS_IDENTITIES_VERIFICATION_CODE_INPUT_ERROR');
      for (let z = 0; z < clone.identities.businesses.length; z++) {
        if (clone.identities.businesses[z]._id === action.data.businessId) {

          for (let y = 0; y < clone.identities.businesses[z].businessIdentities.length; y++) {
            if (clone.identities.businesses[z].businessIdentities[y]._id === action.data.businessIdentitiesId) {
              clone.identities.businesses[z].businessIdentities[y].verificationCodeInputError = true;
              clone.identities.businesses[z].businessIdentities[y].verificationCodeInputErrorMessage = action.data.message;
            }
          }
        }
      }
      return clone;
    }

    case 'INITIALIZE_BUSINESS_DATA':
    {
      const clone = Object.assign({}, state);
      //console.log('INITIALIZE_BUSINESS_DATA');
      //console.log(clone);

      // Overwrite everything (this is the initialization)
      clone.identities.businesses = action.business; // action.business is an array[]

      return clone;
    }

    


    case 'SET_IDENTITY_MANAGEMENT_VERIFICATION_CODE_WEBSITE':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS - SET_IDENTITY_MANAGEMENT_VERIFICATION_CODE_WEBSITE');

      for (let z = 0; z < clone.identities.businesses.length; z++) {
        if (clone.identities.businesses[z]._id === action.data.businessId) {

          for (let y = 0; y < clone.identities.businesses[z].businessIdentities.length; y++) {
            if (clone.identities.businesses[z].businessIdentities[y]._id === action.data.businessIdentitiesId) {
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'website') {
                clone.identities.businesses[z].businessIdentities[y].verificationCode = action.data.websiteVerificationCode;
              }
            }
          }
        }
      }
      return clone;
    }


    case 'SET_BUSINESS_COMMUNICATIONS_TOKEN':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS - SET_COMMUNICATIONS_TOKEN');

      for (let z = 0; z < clone.identities.businesses.length; z++) {
        if (clone.identities.businesses[z]._id === action.data.businessId) {

          for (let y = 0; y < clone.identities.businesses[z].businessIdentities.length; y++) {
            if (clone.identities.businesses[z].businessIdentities[y]._id === action.data.businessIdentitiesId) {
              clone.identities.businesses[z].businessIdentities[y].awaitingVerification = true;

              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'phone') {
                clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'mobile') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'email') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'postal') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'business-name') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'business-brand-image') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'business-brand-words') {
                  clone.identities.businesses[z].businessIdentities[y].communicationToken = action.data.communicationCode;
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = action.data.communicationCodeArray;
              }
              if (clone.identities.businesses[z].businessIdentities[y].type === action.data.businessIdentitiesType
                && action.data.businessIdentitiesType === 'website') {
                  //Website is treated a little differently. There is a websiteVerificationCode that is supplied.
                  clone.identities.businesses[z].businessIdentities[y].websiteVerificationCode = action.data.websiteVerificationCode;

                  clone.identities.businesses[z].businessIdentities[y].communicationToken = '';
                  clone.identities.businesses[z].businessIdentities[y].communicationTokenArray = [];
              }

              // No return response for communication tokens for 'website'
            }
          }
        }
      }
      return clone;
    }


    case 'REDUX_UPDATE_BUSINESSIDENTITIES':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS IDENTITIES - REDUX_UPDATE');
      //console.log(clone);

      const updateRecords = action.updateRecords;

      for (let z = 0; z < updateRecords.length; z++) {
        //console.log('For each record to update...');
        if (updateRecords !== null && updateRecords !== undefined) {
          const dotPath = updateRecords[z].dotPath;
          const updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue); // Update
          _.merge(clone, cloneItemUpdate); // Merge
          //console.log('==== LOOP: CLONE VALUE ====');
          //console.log(clone);
          cloneItemUpdate = null; // Clear
        }
      }
      //console.log('==== FINAL BEFORE RETURNING CLONE ====');
      //console.log(clone);
      clone.identities.ud = Date.now(); // attempting to detect changes in deep-state
      return clone;
    }








    case 'SET_BUSINESS_CONNECT_ACTIVE_PHONE_TOKEN':
    {
      let clone = Object.assign({}, state);
      const businessId = action.tokenObj.businessId;

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === businessId);

      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            {
              businessConnect: businessConnect =>
                update(businessConnect || {}, {
                  phoneTokenActive: { $set: action.tokenObj },
                  // phoneTokens: [{ $push: [action.tokenObj] }],

                  phoneTokens: phoneTokens =>
                    update(phoneTokens || [], {
                      $push: [action.tokenObj],
                    }),


                }),
            },
          },
        },
      });
      /*
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            {
              businessConnect: {
                phoneTokenActive: {},
                phoneTokens: [],
              },
            },
          },
        },
      });

      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            {
              businessConnect: {
                phoneTokenActive: {
                  $set: action.tokenObj,
                },
                phoneTokens: {
                  $push: [action.tokenObj],
                },
              },
            },
          },
        },
      });
      /*

      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            {
              businessConnect: {
                $set: action.tokenObj,

              // businessConnect: businessConnect => update(phoneTokenActive || {}, { $set: action.tokenObj }),


              },
            },
          },
        },
      });
      */
      return clone;
    }

    case 'SET_BUSINESS_CONNECT_ACTIVE_EMAIL_TOKEN':
    {
      let clone = Object.assign({}, state);
      const businessId = action.tokenObj.businessId;

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === businessId);

      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            {
              businessConnect: businessConnect =>
                update(businessConnect || {}, {
                  emailTokenActive: { $set: action.tokenObj },
                  emailTokens: emailTokens =>
                    update(emailTokens || [], {
                      $push: [action.tokenObj],
                    }),
                }),
            },
          },
        },
      });

      return clone;
    }


    case 'ADD_NEW_BUSINESS':
    {
      const clone = Object.assign({}, state);
      //console.log('==== ADD_NEW_BUSINESS ====');
      //console.log(JSON.stringify(state));
      if (typeof(clone.identities.businesses) === 'object') {
        if (clone.identities.businesses === null || clone.identities.businesses === undefined) {
          clone.identities.businesses = [];
        }
      }
      if (action.businesses !== null && action.businesses !== undefined) {
        //const arr = [];
        //arr[0] = action.businesses;
        // _.merge(clone.identities.businesses, arr);
        clone.identities.businesses.push(action.businesses);
      } else {
        console.warn("REDUCER | Business object not set.")
      }
      return clone;
    }

    case 'SYNC_BUSINESS_DATA':
    {
      const clone = Object.assign({}, state);
      /************************************************************
       * This is a key action for syncing the remote businesses 
       * with the local copies. This is typically only the single 
       * business being updated.
       ************************************************************/
      let businessesArray = [];
      if (Array.isArray(clone.identities.businesses)) {
        businessesArray = clone.identities.businesses;
      } else {
        businessesArray.push(clone.identities.businesses);
      }

      const businessId = action.businesses._id;
      if (businessId !== null) {
        for (let x = 0; x < businessesArray.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            businessesArray[x].businessAdministrators = action.businesses.businessAdministrators;
            businessesArray[x].businessContacts = action.businesses.businessContacts;
            businessesArray[x].businessIdentities = action.businesses.businessIdentities;
            businessesArray[x].businessIdentityProfiles = action.businesses.businessIdentityProfiles;
            businessesArray[x].subscriptions = action.businesses.subscriptions;

            try {
              businessesArray[x].accountPostClosure = action.businesses.accountPostClosure;
              businessesArray[x].accountClosureTargetDate = action.businesses.accountClosureTargetDate;
              businessesArray[x].accountPostClosureDate = action.businesses.accountPostClosureDate;

              businessesArray[x].accountClosed = action.businesses.accountClosed;
              businessesArray[x].accountClosedDate = action.businesses.accountClosedDate;
            } catch (e) {}

            try {
              businessesArray[x].accessManagement = action.businesses.accessManagement;
            } catch (e) {}

            try {
              businessesArray[x].businessAgents = action.businesses.businessAgents;
            } catch (e) {}
          }

          

        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }

    
    case 'REMOVE_BUSINESS_DATA':
      {
        const clone = Object.assign({}, state);
        console.log("REMOVE_BUSINESS_DATA");
        /************************************************************
         * Remove the business from the user
         ************************************************************/
        //go through our list, remove the business if it matches one of the received
        let businessesArray = [];
        if (Array.isArray(clone.identities.businesses)) {
          businessesArray = clone.identities.businesses;
        } else {
          businessesArray.push(clone.identities.businesses);
        }
        //Traverse our identies
        try {
          for (let x = clone.identities.businesses.length-1; x >= 0; x--) {
            for (let y = 0; y < businessesArray.length; y++) {
              console.log(`${clone.identities.businesses[x]._id.toString()} === ${businessesArray[y]._id.toString()}`);
              
              if (clone.identities.businesses[x]._id.toString() === businessesArray[y]._id.toString()) {
                console.log('REMOVED ITEM');
                clone.identities.businesses.splice(x, 1);
              }
     
            }   
          }
        } catch (e) {}



        return clone;
      }

    case 'SYNC_BUSINESS_DATA_INVOICES':
    {
      const clone = Object.assign({}, state);
      //console.log('==== SYNC_BUSINESS_DATA_INVOICES ====');
      //console.log(action);
      //Some apis return an object rather than an array. Possible the api needs to be updated for consistent response.
      // business api - updateOne returns an object. Might break other code.
      let businessesArray = [];
      if (Array.isArray(clone.identities.businesses)) {
        businessesArray = clone.identities.businesses;
      } else {
        businessesArray.push(clone.identities.businesses);
      }

      const businessId = action.businessInvoiceData.businessId;
      if (businessId !== null) {
        for (let x = 0; x < businessesArray.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            businessesArray[x].subscriptionInvoices = action.businessInvoiceData.businessInvocies;
          }
        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }


    


    case 'BUSINESS_DATA_REMOVE_UI_DISPLAY_TYPE':
    {
      const clone = Object.assign({}, state);
      //console.log('==== SYNC_BUSINESS_DATA ====');
      //console.log(action);

      const businessId = action.businessId;
      if (businessId !== null) {
        for (let x = 0; x < clone.identities.businesses.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            clone.identities.businesses[x].uiDisplayNote = ''
            clone.identities.businesses[x].uiDisplayType = '';
          }
        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }
    
    case 'BUSINESS_DATA_REMOVE_BUSINESSID':
    {
      const clone = Object.assign({}, state);
      //console.log('==== SYNC_BUSINESS_DATA ====');
      //console.log(action);

      const businessId = action.businessId;
      if (businessId !== null) {
        for (let x = 0; x < clone.identities.businesses.length; x++) {
          if (clone.identities.businesses[x]._id === businessId) {
            //console.log(`=== MATCH | Reducer | Business ${businessId}`);
            //Remove this item
            clone.identities.businesses.splice(x, 1);
          }
        }
      } else {
        console.error('Reducer | businessId is null');
      }

      return clone;
    }

    case 'BUSINESS_IDENTITY_INIT_SET':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITY_INIT_SET');
      //console.log(action);
      clone.identities.businesses = action.data;
      return clone;
    }

    case 'BUSINESS_IDENTITY_SET':
    {
      const clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITY_SET');
      //console.log(action);
      clone.identities = action.identities;
      return clone;
    }


    case 'BUSINESS_IDENTITY_PROFILE_SET':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITY_PROFILE_SET');
      //console.log(action); // businessProfile - data: {businessId, businessProfile},

      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);


      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
            // {"localBusinessProfile":{"businessName":{"$set":"h"}}}
            action.data.reduxUpdateStatement,
          },
        },
      });
      //console.log('clone');
      //console.log(clone);


      /*

      if (businesses && businesses.length > 0) {
        // update the state to the correct business here and this.props.id is the id that you want
        //console.log("reducer - find the business to update (for profile)");
        const business = businesses.find(aBusiness => aBusiness.id === action.data.businessId);
        //console.log(business);
        if (business !== prevState.localBusinessProfile) {
          return { localBusinessProfile: business };
        }


      } */


      // clone.identities = action.identities;
      return clone;
    }




    case 'BUSINESS_IDENTITY_REGISTRY_REPLACE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITY_REGISTRY_REPLACE');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
              action.data.reduxUpdateStatement,
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }

    case 'BUSINESS_IDENTITY_REGISTRY_UPDATE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITY_REGISTRY_UPDATE');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      const indexOfBusinessArrayItem = state.identities.businesses[indexOfBusiness].businessRegistry.findIndex(x => x._id === action.data.reduxUpdateId.arrItemId);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]: {
              businessRegistry: {
                [indexOfBusinessArrayItem]:
                  action.data.reduxUpdateStatement,
              },
            },
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }





    case 'BUSINESS_IDENTITIES_REPLACE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITIES_REPLACE');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
              action.data.reduxUpdateStatement,
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }

    case 'BUSINESS_IDENTITIES_UPDATE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_IDENTITIES_UPDATE');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      const indexOfBusinessArrayItem = state.identities.businesses[indexOfBusiness].businessIdentities.findIndex(x => x._id === action.data.reduxUpdateId.arrItemId);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]: {
              businessIdentities: {
                [indexOfBusinessArrayItem]:
                  action.data.reduxUpdateStatement,
              },
            },
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }

    case 'SET_BUSINESS_IDENTITIES_VERIFICATION_TOKEN':
    {
      let clone = Object.assign({}, state);
      //console.log('SET_BUSINESS_IDENTITIES_VERIFICATION_TOKEN');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      const indexOfBusinessArrayItem = state.identities.businesses[indexOfBusiness].businessIdentities.findIndex(x => x._id === action.data.reduxUpdateId.arrItemId);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]: {
              businessIdentities: {
                [indexOfBusinessArrayItem]:
                  action.data.reduxUpdateStatement,
              },
            },
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }


    case 'BUSINESS_DATA_UPDATE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_DATA_UPDATE');
      // console.log(action);
      // console.log(action.data.reduxUpdateId);
      // console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      // const indexOfBusinessArrayItem = state.identities.businesses[indexOfBusiness].businessIdentities.findIndex(x => x._id === action.data.reduxUpdateId.arrItemId);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
              action.data.reduxUpdateStatement,
          },
        },
      });
      return clone;
    }

    case 'BUSINESS_CONTACTS_UPDATE':
    {
      let clone = Object.assign({}, state);
      //console.log('BUSINESS_CONTACTS_UPDATE');
      //console.log(action);
      //console.log(action.data.reduxUpdateId);
      //console.log(action.data.reduxUpdateStatement);

      const indexOfBusiness = state.identities.businesses.findIndex(x => x._id === action.data.reduxUpdateId.id);
      // const indexOfBusinessArrayItem = state.identities.businesses[indexOfBusiness].businessIdentities.findIndex(x => x._id === action.data.reduxUpdateId.arrItemId);
      clone = update(clone, {
        identities: {
          businesses: {
            [indexOfBusiness]:
              action.data.reduxUpdateStatement,
          },
        },
      });
      //console.log('clone');
      //console.log(clone);
      return clone;
    }


    case 'SET_CONNECT_ACTIVE_PROFILE':
    {
      const clone = Object.assign({}, state);
      //console.log(action);

      const indexOfBusiness = clone.identities.businesses.findIndex(bus => bus._id === action.data.businessId);
      let pathLocal = false;
      //console.log(`Index of Business: ${indexOfBusiness}`);
      try {
        const path = `identities.businesses[${indexOfBusiness}].local`;
        pathLocal = _.get(clone, path, false);
      } catch (e) {
        //console.log('local path failed');
      }

      if (pathLocal === false) {
        //console.log('try write 1');
        try {
          clone.identities.businesses[indexOfBusiness].local = {
            connect: {
              activeProfile: action.data.profileId,
            },
          };
        } catch (e) {
          //console.log('local path creation failed');
        }
      } else {
        try {
          const path = `identities.businesses[${indexOfBusiness}].local.connect`;
          pathLocalConnect = _.get(clone, path, false);
          if (pathLocal === false) {
            //console.log('try write 2');
            try {
              clone.identities.businesses[indexOfBusiness].local.connect = {
                activeProfile: action.data.profileId,
              };
            } catch (e) {
              //console.log('local path creation failed');
            }
          } else {
            //console.log('try write 3');
            clone.identities.businesses[indexOfBusiness].local.connect.activeProfile = action.data.profileId;
          }
        } catch (e) {
          //console.log('local path failed');
        }

      }
      // Set the active profileId
      return clone;
    }


    default:
      return state;
  }
};




export default BusinessIdentitiesReducer;
