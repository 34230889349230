/** *** USER ***** */
import _ from 'lodash';

const initialState = {
  demo: [],                 //To handle multiple demos running at once or across browser tabs
};

const resetState = {
  demo: [],
};

const DemoReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_USER':
    {
      return resetState;
    }

    case 'RESET_STATE_USER':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'REGISTER_DEMO':
    {
      const clone = Object.assign({}, state);

      let demoId = action.data.demoId;
      let mobileUserIdentities = action.data.mobileUserIdentities;

      const indexOfDemo = clone.demo.findIndex(demo => demo.id === demoId);
      console.log(`Index of Demo: ${indexOfDemo}`);

      if (indexOfDemo === -1) {
        //Insert
        clone.demo.push({
          id: demoId,
          identities: mobileUserIdentities,
          date: Date.now(),
        });
      } else {
        //update
        clone.demo[indexOfDemo].identities = mobileUserIdentities;
      }

      return clone;
    }


    default:
      return state;
  }
};

export default DemoReducer;
