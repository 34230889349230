import React, {lazy, Suspense} from 'react';
import { render } from 'react-dom';
import classNames from 'classnames';

import store from 'Store/ConfigureStore';   //migrate to localStore
//import sessionStore from 'Store/ConfigureSessionStore';

//import Root from 'Containers/Root';
//import(/* webpackPrefetch: true */ 'Containers/Root');
const Root = React.lazy(() => import(/* webpackPreload: true */'Containers/Root'));
import { saveLocalStorageState } from './store/localStorage';
//import { saveSessionStorageState } from './store/sessionStorage';



//Load CSS
const componentStyles = React.lazy(() => import(/* webpackPreload: true */'Components/_CommonStyles/commonStyles.css'));
import componentStylesLocal from './styles.css';

/////////////////////////////////////////////////
// GLOBAL VARIABLES
/////////////////////////////////////////////////
global.gpAccessToken = '';
global.gpRefreshToken = '';
global.fbAccessToken = '';



//Langauge Support
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";


import common_en from "./translations/en/common.json";
import common_pt from "./translations/pt/common.json";
import common_de from "./translations/de/common.json";


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      pt: {
        common: common_pt
      },
      de: {
          common: common_de
      },
  },
});


if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

__webpack_nonce__ = '<%=nonce%>';


store.subscribe(() => {
  saveLocalStorageState(store.getState());
});
/*
sessionStore.subscribe(() => {
  saveSessionStorageState(sessionStore.getState());
});
*/

const LoadingComponent = () => (
  <div id="top">
    <div className={classNames({ indexPage100vhContainer: true })}>

      <div className={classNames({ indexHeader_title: true,  })}>

        <div className={classNames({ indexBannerTagLine: true,  })}>
          <span>LOADING</span>
        </div>

      </div>
    </div>
 
  </div>
);


render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<LoadingComponent/>}>
        <Root store={store} />
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);