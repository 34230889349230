import _ from 'lodash';

const initialState = {
  products: [],
  subscriptionProducts: [],

};

const resetState = {
  products: [],
  subscriptionProducts: [],
};


const ProductsReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_PRODUCT':
    {
      return resetState;
    }

    case 'RESET_STATE_PRODUCT':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'SET_SUBSCRIPTION_PRODUCTS':
    {
      const clone = Object.assign({}, state);
      clone.subscriptionProducts = action.subscriptionProducts;
      return clone;
    }
    case 'SET_PRODUCTS':
    {
      const clone = Object.assign({}, state);
      clone.products = action.products;
      return clone;
    }

    case 'SYNC_PRODUCTS':
    {
      const clone = Object.assign({}, state);
      clone.products = action.data.products;
      clone.subscriptionProducts = action.data.subscriptionProducts;
      return clone;
    }


    default:
      return state;
  }
};

export default ProductsReducer;
