import _ from 'lodash';

/** *** UI STATE ***** */
const initialState = {
  windowSize: {},
  isMobile: false,
  sidebarOpen: false,
  appbarHeight: '45px',
  subSidebarOpen: false,
  uiApiActivity: [],          //Manage the visibility of API Calls
  uiRadioOptions: [],
  uiTabOptions: [],
  uiCheckboxOptions: [],
  uiToggleOptions: [],
  uiPagination: [
    {
      pageGroup: 'LogsA',
      businessId: 'b',
      userId: 'u',
      options: {
        itemsPerPage: 10,
        currentPage: 2,
        totalItems: 100,
      },
    }
  ],
  uiToastMessages: [
    /*
    {
      id: '11111111', 
      message: "Hello...",
      type: "success",
      seen: false,
    },
    */
  ]
};

const resetState = {
  windowSize: {},
  isMobile: false,
  sidebarOpen: false,
  appbarHeight: '45px',
  subSidebarOpen: false,
  uiApiActivity: [],          //Manage the visibility of API Calls
  uiRadioOptions: [],
  uiTabOptions: [],
  uiCheckboxOptions: [],
  uiToggleOptions: [],
  uiPagination: [],
  uiToastMessages: []
};


const UIReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_UI':
    {
      return resetState;
    }

    case 'RESET_STATE_UI':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }


    //From CommonTransit
    case 'SET_DEVICE_TYPE_IS_MOBILE':
      {
        const clone = Object.assign({}, state);
        try {
          clone.isMobile = action.status;
        } catch (e) {}
        
        return clone;
      }

    case 'ADD_GLOBAL_TOAST_MESSAGE':
    {
      const clone = Object.assign({}, state);
      try {
        clone.uiToastMessages.push({
          id: action.data.id,
          message: action.data.message,
          type: action.data.type,
          seen: false,
        });
      } catch (e) {}
      
      return clone;
    }

    case 'REMOVE_GLOBAL_TOAST_MESSAGE':
    {
      const clone = Object.assign({}, state);

      let foundRecord = false;
      let removeRecord = true;
      let removeRecordId = null;
      if (clone.uiToastMessages) {
        for (let z = 0; z < clone.uiToastMessages.length; z++) {
          //console.log('For each record in uiToastMessages');

          // id Matches
          if (clone.uiToastMessages[z].id === action.data.id) {
            // remove from the ui
            removeRecord = true;
            removeRecordId = z;
          }
        }
      }
      if (removeRecord === true) {
        clone.uiToastMessages.splice(removeRecordId, 1);
        //console.log(`Removed uiToastMessages item at position ${  removeRecordId}`);
      }
     
      
      return clone;
    }




    case 'SET_WINDOW_SIZE':
    {
      const clone = Object.assign({}, state);
      //console.log("SET_WINDOW_SIZE");
      //console.log(action);
      //console.log(action.windowSize);

      clone.windowSize = action.windowSize;
      
      return clone;
    }


    case 'SET_UI_PAGINATION':
    {
      const clone = Object.assign({}, state);
      //console.log('SET_UI_PAGINATION');
      //console.log(action);
      let foundRecord = false;
      let removeRecord = false;
      let removeRecordId = null;
      if (clone.uiPagination) {
        for (let z = 0; z < clone.uiPagination.length; z++) {
          //console.log('For each record in uiPagination');
          
          if (clone.uiPagination[z].pageGroup === action.obj.pageGroup) {
            foundRecord = true;

          }
        }
      }
      if (removeRecord === true) {
        clone.uiPagination.splice(removeRecordId, 1);
        //console.log(`Removed uiPagination item at position ${removeRecordId}`);
      }
      if (foundRecord === false) {
        clone.uiPagination.push({
          pageGroup: action.obj.pageGroup,
          businessId: action.obj.businessId,
          userId: action.obj.userId,
          options: {
            itemsPerPage: 10,
            currentPage: 2,
            totalItems: 100,
          },
          startTime: Date.now(),
        });
      }
      return clone;
    }


    case 'SET_UI_RADIO_OPTION':
    {
      const clone = Object.assign({}, state);
      //console.log(action);
      //console.log(`UI businessId: ${action.obj.businessId}`);
      //console.log(`UI radioOptionsGroup: ${action.obj.radioOptionsGroup}`);
      //console.log(`UI radioOptionsId: ${action.obj.radioOptionsId}`);
      //console.log(`UI radioSelectedState: ${action.obj.radioSelectedState}`);
      let foundRecord = false;
      let removeRecord = false;
      let removeRecordId = null;
      if (clone.uiRadioOptions) {
        for (let z = 0; z < clone.uiRadioOptions.length; z++) {
          //console.log('For each record in uiRadioOptions');

          // BusinessId Matches
          if (clone.uiRadioOptions[z].businessId === action.obj.businessId) {
            // Radio Option Group Matches
            if (clone.uiRadioOptions[z].radioOptionsGroup === action.obj.radioOptionsGroup) {
              foundRecord = true;
              // Radio Option Id Matches and the state is currently False
              if (clone.uiRadioOptions[z].selectedRadioOptionsId === action.obj.radioOptionsId &&
                action.obj.radioSelectedState === false) {
                // remove from the ui
                removeRecord = true;
                removeRecordId = z;
              }
              if (action.obj.radioSelectedState === false) {
                clone.uiRadioOptions[z].selectedRadioOptionsId = action.obj.radioOptionsId;
              }
            }
          }
        }
      }
      if (removeRecord === true) {
        clone.uiRadioOptions.splice(removeRecordId, 1);
        //console.log(`Removed uiRadioOptions item at position ${  removeRecordId}`);
      }
      if (foundRecord === false) {
        //console.log(`UI businessId: ${action.obj.businessId}`);
        //console.log(`UI radioOptionsGroup: ${action.obj.radioOptionsGroup}`);
        //console.log(`UI radioOptionsId: ${action.obj.radioOptionsId}`);
        //console.log(`UI radioSelectedState: ${action.obj.radioSelectedState}`);
        clone.uiRadioOptions.push({
          businessId: action.obj.businessId,
          radioOptionsGroup: action.obj.radioOptionsGroup,
          selectedRadioOptionsId: action.obj.radioOptionsId,
          startTime: Date.now(),
        });
      }
      return clone;
    }


    

    case 'SET_UI_CHECKBOX_OPTION':
    {
      const clone = Object.assign({}, state);
      console.log("SET_UI_CHECKBOX_OPTION");
      console.log(action);
      console.log(`UI businessId: ${action.obj.businessId}`);
      console.log(`UI checkboxOptionsId: ${action.obj.checkboxOptionsId}`);
      console.log(`UI checkboxSelectedState: ${action.obj.checkboxSelectedState}`);


      let checkboxOptionsGroup = '';
      try {
        checkboxOptionsGroup = action.obj.checkboxOptionsGroup;
      } catch (e) {}

      if (checkboxOptionsGroup === undefined) {
        checkboxOptionsGroup = '';
      }

      ///////////////////////////////////////////////////////
      // OVERRIDE A CHECKBOX OPTION GROUP (e.g. "all". Would remove all the individual settings)
      ///////////////////////////////////////////////////////
      let overrideCheckboxOptionGroup = false;
      try {
        overrideCheckboxOptionGroup = action.obj.overrideCheckboxOptionGroup;
      } catch (e) {}
      if (overrideCheckboxOptionGroup === true) {
        if (clone.uiCheckboxOptions) {
          for (let z = clone.uiCheckboxOptions.length; z >= 0; z--) {
            //console.log('For each record in uiCheckboxOptions');
            if (action.obj.businessId !== '' || action.obj.businessId !== undefined) {
              //match the business id first
              let businessId = '';
              try {
                businessId = clone.uiCheckboxOptions[z].businessId;
              } catch (e) {}
              if (businessId === action.obj.businessId &&
              clone.uiCheckboxOptions[z].checkboxOptionsGroup === action.obj.checkboxOptionsGroup) {
                clone.uiCheckboxOptions.splice(z, 1);
              }
            } 
          }
        }
      }

      ///////////////////////////////////////////////////////
      // INSERT CHECKBOX RECORD
      ///////////////////////////////////////////////////////

    /*
    obj:
    businessId: "12345"
    checkboxOptionsGroup: "observation_backAbnormality"
    checkboxOptionsId: "observation_backAbnormality-None"
    checkboxSelectedState: false
    overrideCheckboxOptionGroup: true
    */

      let foundRecord = false;
      if (clone.uiCheckboxOptions) {
        for (let z = 0; z < clone.uiCheckboxOptions.length; z++) {
          //console.log('For each record in uiCheckboxOptions');
          if (action.obj.businessId !== '' || action.obj.businessId !== undefined) {
            //match the business id first
            let businessId = '';
            try {
              businessId = clone.uiCheckboxOptions[z].businessId;
            } catch (e) {}
            if (businessId === action.obj.businessId &&
              clone.uiCheckboxOptions[z].checkboxOptionsId === action.obj.checkboxOptionsId) {
                console.log("====== MATCH ======");
                console.log(action.obj.checkboxOptionsId);
                console.log(`${clone.uiCheckboxOptions[z].checkboxSelectedState} = ${!clone.uiCheckboxOptions[z].checkboxSelectedState}`);
              foundRecord = true;
              clone.uiCheckboxOptions[z].checkboxSelectedState = !clone.uiCheckboxOptions[z].checkboxSelectedState;
            }
          } else {
            //No need to match on business id
            
            if (clone.uiCheckboxOptions[z].checkboxOptionsId === action.obj.checkboxOptionsId) {
              foundRecord = true;
              clone.uiCheckboxOptions[z].checkboxSelectedState = !clone.uiCheckboxOptions[z].checkboxSelectedState;
            }
          }
        }
      }
      if (foundRecord === false) {
        console.log("====== INSERT NEW RECORD ======");
        if (overrideCheckboxOptionGroup === true) {
          clone.uiCheckboxOptions.push({
            businessId: action.obj.businessId,
            checkboxOptionsGroup: checkboxOptionsGroup,
            checkboxOptionsId: action.obj.checkboxOptionsId,
            //We clear all group state, which means when we toggle, we need to match the toggle state
            checkboxSelectedState: action.obj.checkboxSelectedState,
            default: true,              //Default state. Used to identify
            startTime: Date.now(),
          });
        } else {
          clone.uiCheckboxOptions.push({
            businessId: action.obj.businessId,
            checkboxOptionsGroup: checkboxOptionsGroup,
            checkboxOptionsId: action.obj.checkboxOptionsId,
            //We clear all group state, which means when we toggle, we need to match the toggle state
            checkboxSelectedState: action.obj.checkboxSelectedState,
            startTime: Date.now(),
          });
        }
      }

      



      return clone;
    }
    
    case 'SET_UI_TOGGLE_OPTION':
    {
      const clone = Object.assign({}, state);
      //console.log(action);
      //console.log(`UI businessId: ${action.obj.businessId}`);
      //console.log(`UI toggleOptionsId: ${action.obj.toggleOptionsId}`);
      let foundRecord = false;
      if (clone.uiToggleOptions) {
        for (let z = 0; z < clone.uiToggleOptions.length; z++) {
          //console.log('For each record in uiToggleOptions');
          if (clone.uiToggleOptions[z].toggleOptionsId === action.obj.toggleOptionsId) {
            foundRecord = true;
            clone.uiToggleOptions[z].toggleSelectedState = !clone.uiToggleOptions[z].toggleSelectedState;
          }
        }
      }
      if (foundRecord === false) {
        clone.uiToggleOptions.push({
          businessId: action.obj.businessId,
          toggleOptionsId: action.obj.toggleOptionsId,
          toggleSelectedState: true,
          startTime: Date.now(),
        });
      }
      return clone;
    }

    case 'SET_UI_TAB_OPTION':
      {
        const clone = Object.assign({}, state);
        //console.log(action);
        //console.log(`UI businessId: ${action.obj.businessId}`);
        //console.log(`UI tabOptionsGroup: ${action.obj.tabOptionsGroup}`);
        //console.log(`UI tabOptionsId: ${action.obj.tabOptionsId}`);
        //console.log(`UI tabSelectedState: ${action.obj.tabSelectedState}`);
        
        let foundRecord = false;
        let removeRecord = false;
        let removeRecordId = null;
        if (clone.uiTabOptions) {
          for (let z = 0; z < clone.uiTabOptions.length; z++) {
            //console.log('For each record in uiTabOptions');
  
            // BusinessId Matches
            if (clone.uiTabOptions[z].businessId === action.obj.businessId) {
              // Radio Option Group Matches
              if (clone.uiTabOptions[z].tabOptionsGroup === action.obj.tabOptionsGroup) {
                foundRecord = true;

                clone.uiTabOptions[z].selectedTabOptionsId = action.obj.tabOptionsId;
                 
              }
            }
          }
        }
        if (removeRecord === true) {
          clone.uiTabOptions.splice(removeRecordId, 1);
          //console.log(`Removed uiTabOptions item at position ${  removeRecordId}`);
        }
        if (foundRecord === false) {
          //console.log(`UI businessId: ${action.obj.businessId}`);
          //console.log(`UI tabOptionsGroup: ${action.obj.tabOptionsGroup}`);
          //console.log(`UI tabOptionsId: ${action.obj.tabOptionsId}`);
          //console.log(`UI tabSelectedState: ${action.obj.tabSelectedState}`);
          clone.uiTabOptions.push({
            businessId: action.obj.businessId,
            tabOptionsGroup: action.obj.tabOptionsGroup,
            selectedTabOptionsId: action.obj.tabOptionsId,
            startTime: Date.now(),
          });
        }
        return clone;
      }



    case 'SET_UI_API_ACTIVITY':
    {
      const clone = Object.assign({}, state);
      //console.log(action);
      //console.log(`UI ID: ${action.obj.id}`);
      //console.log(`UI STATUS: ${action.obj.status}`);
      let foundRecord = false;
      for (let z = 0; z < clone.uiApiActivity.length; z++) {
        //console.log('For each record in uiApiActivity');
        if (clone.uiApiActivity[z].id === action.obj.id) {
          clone.uiApiActivity[z].status = action.obj.status;
          foundRecord = true;
        }
      }
      if (foundRecord === false) {
        clone.uiApiActivity.push({
          id: action.obj.id,
          status: 'inprogress', // 'inprogress/successful/failed'
          startTime: Date.now(),
        });
      }
      return clone;
    }

    case 'UPDATE_UI_API_ACTIVITY':
    {
      const clone = Object.assign({}, state);
      for (let z = 0; z < clone.uiApiActivity.length; z++) {
        //console.log('For each record in uiApiActivity');
        if (clone.uiApiActivity[z].id === action.obj.id) {
          clone.uiApiActivity[z].status = action.obj.status;
        }
      }
      return clone;
    }

    case 'REMOVE_UI_API_ACTIVITY':
    {
      const clone = Object.assign({}, state);

      clone.uiApiActivity = _.filter(clone.uiApiActivity, f => f.id !== action.obj.id);

      /*
      for (let z = 0; z < clone.uiApiActivity.length; z++) {
        console.log('For each record in uiApiActivity');
        if (clone.uiApiActivity[z].id === action.obj.id) {
          console.log('Remove this item from uiApiActivity');
          clone.uiApiActivity[z].status = 'REMOVED';
        }
      }
      */
      return clone;
    }


    case 'SET_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      // console.log("clone");
      // console.log(clone);
      clone.sidebarOpen = action.sidebarOpen;
      // console.log(clone);

      return clone;
    }

    case 'SET_SUB_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      // console.log("clone");
      // console.log(clone);
      clone.subSidebarOpen = action.subSidebarOpen;
      // console.log(clone);

      return clone;
    }

    case 'SET_APP_BAR_HEIGHT':
    {
      const clone = Object.assign({}, state);
      clone.appbarHeight = action.appbarHeight;
      return clone;
    }





    default:
      return state;
  }
};

export default UIReducer;
