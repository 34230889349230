import _ from 'lodash';
/** *** CORE STATE ***** */
/*
Global information
- 
*/





const initialState = {
  selby_counter: 0,
  selby: 'aaaa',
  selby2: 'bbbb',


  xyz: 'z',


  lastStateReset: '',
  lastAPISync: '',
  lastAPICall: '',

  test1: 'aaa',
  test2: [],
  test3: {
    one:'1',
    two:'2',
  },
  /*
  test4: [
    {item1: 'a', desc1: 'b'},
    {item2: 'c', desc2: 'd'},
  ],
  test5: {
    nested1: {
      arr1: [1, 2, 3, 4],
      arr2: ["a", "b", "c", "d"],
      arr3: [
        {item1: 'a', desc1: 'b'},
        {item2: 'c', desc2: 'd'},
      ],
    },
    nested2: {
      arr1: [5, 6, 7, 8],
      arr2: ["e", "f", "g", "h"],
      arr3: [
        {name: 'a', prop1: '', prop2: '', item1: 'e', desc1: 'f'},
        {name: 'b', prop1: '', prop2: '', item2: 'g', desc2: 'h'},
      ],
    }
  }
  */
};

const resetState = {
  selby_counter: 0,
  selby: 'aaaa',
  selby2: 'bbbb',


  xyz: 'z',


  lastStateReset: '',
  lastAPISync: '',
  lastAPICall: '',

  test1: 'aaa',
  test2: [],
  test3: {
    one:'1',
    two:'2',
  },
};





const CoreReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_CORE':
    {
      return resetState;
    }


    case 'RESET_STATE_CORE':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }


    
    case 'INCREMENT_COUNTER':
    {
      const clone = Object.assign({}, state);

      if (clone.selby_counter === undefined) {
        clone.selby_counter = 0;
      }

      let counter = clone.selby_counter;
      counter = counter+1;

      clone.selby_counter = counter;

      
      return clone;
    }





    case 'INSERT_ITEM':
    {
      //GOAL - Insert an item based on position
      //If it is existing value, use the "update" function
      // insert into an array, or insert into an object (key:value)
      
      const clone = Object.assign({}, state);
      //console.log('CORE - INSERT ITEM  - INSERT_ITEM');
      //console.log(clone);
      //console.log(action);
      //console.log(JSON.stringify(action));

      let insertRecords = action.insertRecords;
      //console.log("array");
      //console.log(insertRecords);
      
      for(let z=0; z<insertRecords.length; z++) {
        //console.log("For each record to insert...");
        if (insertRecords!==null && insertRecords!==undefined) {
          let dotPath = insertRecords[z].dotPath;
          let updateValue = insertRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          //get dotPath to determine field type
          let dotPathValue = _.get(clone, dotPath);
          if (typeof(dotPathValue)==='object') {
            if (Array.isArray(dotPathValue)) {
              //It is an array, push item into array
              //console.log(dotPath+" is an array");
              dotPathValue.push(updateValue);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, dotPathValue); 
            } else {
              //merge the value into the existing 
              //console.log(dotPath+" is an object");
              let tempValueMerge = _.merge(dotPathValue, updateValue);  
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, tempValueMerge); 
            }
          } else {
            //console.log(dotPath+" is NOT an array or object");
            _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          }
          //in all cases, we merge back
          _.merge(clone, cloneItemUpdate);                      //Merge
          
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }

      
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }

    case 'TEST20_REMOVE_ITEM':
    {
      //GOAL - Given a path, remove the item.
      // if its an array, remove the value (string, integer, object, array)

      const clone = Object.assign({}, state);
      //console.log('CORE - REMOVE ITEM  - TEST20_REMOVE_ITEM');
      //console.log(clone);

      let removeRecords = action.removeRecords;
      
      for(let z=0; z < removeRecords.length; z++) {
        //console.log("For each record to update...");
        if (removeRecords!==null && removeRecords!==undefined) {
          /*
          for each record to remove, start with the predecessors in
          reverse order. This will start at the deeply nested value first
          replacing the array entirely.
          We will then move up the tree, updating and replaing the array 
          with children entirely.
          */
          //The following do not change for this item being removed.
          let dotPath = removeRecords[z].dotPath;
          let updateValue = removeRecords[z].updateValue;

          //Traverse through the predecessors in reverse order.
          let dotPathPredecessorReferencesArray = removeRecords[z].dotPathPredecessorReferences;
          
          _.unset(clone, dotPath); 

          if (dotPathPredecessorReferencesArray.length>0) {
            _.reverse(dotPathPredecessorReferencesArray);
            for (let y=0; y < dotPathPredecessorReferencesArray; y++) {
              let dotPathPredecessor = dotPathPredecessorReferencesArray[y].dotPathPredecessor;
              let dotPathPredecessorIndex = dotPathPredecessorReferencesArray[y].dotPathPredecessorIndex;
              
              //Take a clone of current state.
              let cloneItemUpdate = Object.assign({}, clone);
              //Get the value of the parent of the item we are removing.
              let predecessorArray = _.get(clone, dotPathPredecessor);
              //console.log(predecessorArray);
              var filteredArray = predecessorArray.filter(function (el) {
                return el != null;
              });
              //console.log(filteredArray);
              //let pulled = _.pullAt(getvalue, dotPathPredecessorIndex);
              //console.log(pulled);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPathPredecessor, filteredArray);
              //console.log(cloneItemUpdate);
              _.merge(clone, cloneItemUpdate);  

            }

          }

          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          //cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }

    case 'CHANGE_STATE':
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - CHANGE_STATE');
      const dateNow = Date(Date.now()); 
      clone.lastStateReset = dateNow.toString();
      return clone;
    }

    case 'EXAMPLE_UPDATE':    //DETAILS
    {
      /* Required inputs: dotPath, updateValue
      */
      //(1) - Clone State
      //(2) - Create an Update Clone of State for changes
      //(3) - Set the new values on the update Clone. When updating, all other fields in the Update Clone will be removed resulting with just the single update field.
      //(4) - With the updated object, merge this back into clone. (clone, cloneItemUpdate) - This will overwrite clone with any changes in cloneItemUpdate.
      const clone = Object.assign({}, state);
      let cloneItemUpdate = Object.assign({}, clone);
      _.set(cloneItemUpdate, 'test5.nested1.arr3[0]', { item1: 'aaaaa', desc1: 'bbbbb' });    //PATH & UPDATE is defined in ACTIONS (reducer just implements)
      _.merge(clone, cloneItemUpdate);
      return clone;
    }

    case 'TEST_UPDATE':
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - TEST_UPDATE');
      //console.log(clone);
      let cloneItemUpdate = Object.assign({}, clone);
      _.set(cloneItemUpdate, 'test5.nested1.arr3[0]', { item1: 'aaaaa', desc1: 'bbbbb' });    //PATH & UPDATE is defined in ACTIONS (reducer just implements)
      //console.log(cloneItemUpdate);
      //Remerge
      _.merge(clone, cloneItemUpdate);
      //console.log(clone);
      
      return clone;
    }

    case 'TEST_UPDATE_2':
    {
      //GOAL - Update 2 different fields in the same update, but leave everything else.
      //STATUS - WORKS
      //REQUIRES - Provide an updateRecords array with the changes required.
      const clone = Object.assign({}, state);
      //console.log('CORE - TEST_UPDATE_2');
      //console.log(clone);
      
      let updateRecords = [
        {
          dotPath: 'test5.nested1.arr3[0]',
          updateValue: { item1: 'aaaaa', desc1: 'bbbbb' }
        },
        {
          dotPath: 'test5.nested2.arr3[1]',
          updateValue: { item1: 'zzzzz', desc1: 'yyyyy' }
        },
      ];

      for(let z=0; z<updateRecords.length; z++) {
        //console.log("For each record to update...");
        let dotPath = updateRecords[z].dotPath;
        let updateValue = updateRecords[z].updateValue;

        let cloneItemUpdate = Object.assign({}, clone);
        _.set(cloneItemUpdate, dotPath, updateValue);         //Update
        _.merge(clone, cloneItemUpdate);                      //Merge
        //console.log("==== LOOP: CLONE VALUE ====");
        //console.log(clone); 
        cloneItemUpdate=null;                                 //Clear
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }

    case 'TEST_UPDATE_2a':
    {
      //GOAL - TEST DATA FROM ACTION

      const clone = Object.assign({}, state);
      //console.log('CORE - TEST_UPDATE_2a');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        //console.log("For each record to update...");
        let dotPath = updateRecords[z].dotPath;
        let updateValue = updateRecords[z].updateValue;

        let cloneItemUpdate = Object.assign({}, clone);
        _.set(cloneItemUpdate, dotPath, updateValue);         //Update
        _.merge(clone, cloneItemUpdate);                      //Merge
        //console.log("==== LOOP: CLONE VALUE ====");
        //console.log(clone); 
        cloneItemUpdate=null;                                 //Clear
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }




    case 'TEST_UPDATE_3':
    {
      //GOAL - TEST DATA FROM ACTION (dynamic data)

      const clone = Object.assign({}, state);
      //console.log('CORE - TEST_UPDATE_2a');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        //console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }

    








    /************************************************************************
    ***** NEW REDUX ACTIONS *************************************************
    *************************************************************************/

    case 'REDUX_UPDATE_CORE':
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - REDUX_UPDATE');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        //console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }

    case 'REDUX_INSERT_CORE':    //insertRecords
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - REDUX_INSERT');
      let insertRecords = action.insertRecords;
     
      for(let z=0; z<insertRecords.length; z++) {
        //console.log("For each record to insert...");
        if (insertRecords!==null && insertRecords!==undefined) {
          let dotPath = insertRecords[z].dotPath;
          let updateValue = insertRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          //get dotPath to determine field type
          let dotPathValue = _.get(clone, dotPath);
          if (typeof(dotPathValue)==='object') {
            if (Array.isArray(dotPathValue)) {
              //It is an array, push item into array
              //console.log(dotPath+" is an array");
              dotPathValue.push(updateValue);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, dotPathValue); 
            } else {
              //merge the value into the existing 
              //console.log(dotPath+" is an object");
              let tempValueMerge = _.merge(dotPathValue, updateValue);  
              cloneItemUpdate = _.set(cloneItemUpdate, dotPath, tempValueMerge); 
            }
          } else {
            //console.log(dotPath+" is NOT an array or object");
            _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          }
          //in all cases, we merge back
          _.merge(clone, cloneItemUpdate);                      //Merge
          
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }
    case 'REDUX_REMOVE_CORE':    //removeRecords
    {
      const clone = Object.assign({}, state);
      //console.log('CORE - REMOVE ITEM  - REDUX_REMOVE');
      //console.log(clone);

      let removeRecords = action.removeRecords;
      
      for(let z=0; z < removeRecords.length; z++) {
        //console.log("For each record to update...");
        if (removeRecords!==null && removeRecords!==undefined) {
          /*
          for each record to remove, start with the predecessors in
          reverse order. This will start at the deeply nested value first
          replacing the array entirely.
          We will then move up the tree, updating and replaing the array 
          with children entirely.
          */
          //The following do not change for this item being removed.
          let dotPath = removeRecords[z].dotPath;
          let updateValue = removeRecords[z].updateValue;

          //Traverse through the predecessors in reverse order.
          let dotPathPredecessorReferencesArray = removeRecords[z].dotPathPredecessorReferences;
          
          _.unset(clone, dotPath); 

          if (dotPathPredecessorReferencesArray.length>0) {
            _.reverse(dotPathPredecessorReferencesArray);
            for (let y=0; y < dotPathPredecessorReferencesArray; y++) {
              let dotPathPredecessor = dotPathPredecessorReferencesArray[y].dotPathPredecessor;
              let dotPathPredecessorIndex = dotPathPredecessorReferencesArray[y].dotPathPredecessorIndex;
              
              //Take a clone of current state.
              let cloneItemUpdate = Object.assign({}, clone);
              //Get the value of the parent of the item we are removing.
              let predecessorArray = _.get(clone, dotPathPredecessor);
              //console.log(predecessorArray);
              var filteredArray = predecessorArray.filter(function (el) {
                return el != null;
              });
              //console.log(filteredArray);
              //let pulled = _.pullAt(getvalue, dotPathPredecessorIndex);
              //console.log(pulled);
              cloneItemUpdate = _.set(cloneItemUpdate, dotPathPredecessor, filteredArray);
              //console.log(cloneItemUpdate);
              _.merge(clone, cloneItemUpdate);  
            }
          }
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          //cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }






    default:
      return state;
  }
};

export default CoreReducer;
