/** *** VERIFY ***** */
import _ from 'lodash';
import update from 'immutability-helper';


const initialState = {
  verificationLookup: {
    code: '',
  },
  verifications: [],
  thisVerification: {},
};

const resetState = {
  verificationLookup: {
    code: '',
  },
  verifications: [],
  thisVerification: {},
};

const VerifyReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_VERIFY':
    {
      return resetState;
    }

    case 'RESET_STATE_VERIFY':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'VERIFY_INTERACTION':
    {
      let clone = Object.assign({}, state);
      //console.log('VERIFY_INTERACTION');
      //console.log(action);

      // Improve this - if you search for the same token ID twice, don't add it as separate ID's

      clone = update(clone, {
        verifications: {
          $push: [
            {
              business: action.transaction.businessResponse,
              transaction: action.transaction.transactionResponse,
            },
          ],
        },
        thisVerification: {
          $set: {
            business: action.transaction.businessResponse,
            transaction: action.transaction.transactionResponse,
            status: action.transaction.status,
          },
        },
      });
      return clone;
    }




    /************************************************************************
    ***** NEW REDUX ACTIONS *************************************************
    *************************************************************************/

   case 'REDUX_UPDATE_VERIFY':
    {
      const clone = Object.assign({}, state);
      //console.log('VERIFY - REDUX_UPDATE');
      //console.log(clone);

      let updateRecords = action.updateRecords;
      
      for(let z=0; z<updateRecords.length; z++) {
        //console.log("For each record to update...");
        if (updateRecords!==null && updateRecords!==undefined) {
          let dotPath = updateRecords[z].dotPath;
          let updateValue = updateRecords[z].updateValue;

          let cloneItemUpdate = Object.assign({}, clone);
          _.set(cloneItemUpdate, dotPath, updateValue);         //Update
          _.merge(clone, cloneItemUpdate);                      //Merge
          //console.log("==== LOOP: CLONE VALUE ====");
          //console.log(clone); 
          cloneItemUpdate=null;                                 //Clear 
        }                                
      }
      //console.log("==== FINAL BEFORE RETURNING CLONE ===="); 
      //console.log(clone); 
      return clone;
    }




    default:
      return state;
  }
};

export default VerifyReducer;
