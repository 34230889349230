import _ from 'lodash';


const initialState = {
  eventLog: [],
  connection: false,

  authEventLog: [],
  authConnection: false,
  
};

const resetState = {
  eventLog: [],
  connection: false,

  authEventLog: [],
  authConnection: false,
};


const SocketReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_SOCKET':
    {
      return resetState;
    }

    case 'RESET_STATE_SOCKET':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    //Update the event log (for troubleshooting)
    case 'UPDATE_SOCKET_EVENT_LOG':
    {
      const clone = Object.assign({}, state);
      console.log('UPDATE_SOCKET_EVENT_LOG');

      try {
        clone.eventLog.push(action.data);
      } catch (e) {
        console.log('Failed to update socket eventlog');
        console.log(e);
      }

      return clone;
    }

    //Add the socket connection to true (active)
    case 'SET_SOCKET_CONNECTION':
    {
      const clone = Object.assign({}, state);
      console.log('SET_SOCKET_CONNECTION');

      try {
        clone.connection = true;
      } catch (e) {
        console.log('Failed to set the socket connection');
        console.log(e);
      }

      return clone;
    }

    //Add the socket connection to false (inactive)
    case 'SET_SOCKET_DISCONNECTION':
    {
      const clone = Object.assign({}, state);
      console.log('SET_SOCKET_DISCONNECTION');

      try {
        clone.connection = false;
      } catch (e) {
        console.log('Failed to set the socket connection');
        console.log(e);
      }

      return clone;
    }

    /******************** AUTH SOCKETS *********************/
    //Add the socket connection to true (active)
    case 'SET_AUTH_SOCKET_CONNECTION':
    {
      const clone = Object.assign({}, state);
      console.log('SET_AUTH_SOCKET_CONNECTION');

      try {
        clone.authConnection = true;
      } catch (e) {
        console.log('Failed to set the auth socket connection');
        console.log(e);
      }

      return clone;
    }

    //Add the socket connection to false (inactive)
    case 'SET_AUTH_SOCKET_DISCONNECTION':
    {
      const clone = Object.assign({}, state);
      console.log('SET_AUTH_SOCKET_DISCONNECTION');

      try {
        clone.authConnection = false;
      } catch (e) {
        console.log('Failed to set the auth socket connection');
        console.log(e);
      }

      return clone;
    }



    
    default:
      return state;
  }
};




export default SocketReducer;
