import _ from 'lodash';


/** *** SIDEBAR ***** */
const initialState = {
  sidebarOpen: false,
};

const resetState = {
  sidebarOpen: false,
};


const SideBarReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_SIDEBAR':
    {
      return resetState;
    }

    case 'RESET_STATE_SIDEBAR':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'SET_SIDEBAR_OPEN_STATE':
    {
      const clone = Object.assign({}, state);
      clone.sidebarOpen = action.sidebarOpen;
      return clone;
    }

    default:
      return state;
  }
};

export default SideBarReducer;
