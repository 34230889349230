import update from 'immutability-helper';
import _ from 'lodash';

const initialState = {
  syncTimer: '',
  syncHistoryUser: [],
  lastSyncUser: '',
  syncHistoryBusiness: [],
  lastSyncBusiness: '',
  syncHistorySoftware: [],
  lastSyncSoftware: '',

  dataRecordUpdates: [],

  /*
  dataRecordUpdates:[
    {
      name: identities.$.identity
      identifiers: []
      stateIdentifier: "User",

    }

  ]

  */


};

const resetState = {
  syncTimer: '',
  syncHistoryUser: [],
  lastSyncUser: '',
  syncHistoryBusiness: [],
  lastSyncBusiness: '',
  syncHistorySoftware: [],
  lastSyncSoftware: '',

  dataRecordUpdates: [],
};


const SyncReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_SYNC':
    {
      return resetState;
    }

    case 'RESET_STATE_SYNC':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'SYNC_CREATE_TIMER':
    {
      const clone = Object.assign({}, state);
      clone.syncTimer = action.syncTimerId;
      return clone;
    }


    case 'SYNC_DATA_TRANSACTION_TRACKING':
    {
      const clone = Object.assign({}, state);
      //console.log('USER - SYNC_DATA_TRANSACTION_TRACKING');
      //console.log(`CLONE BEFORE SYNC`);
      //console.log(JSON.stringify(clone));
      //console.log(JSON.stringify(action.updateRecords));



      let foundMatch = false;
      for (let x = 0; x < clone.dataRecordUpdates.length; x++) {
        //console.log(`${clone.dataRecordUpdates[x].state} === ${action.updateRecords.state}`);
        //console.log(`${clone.dataRecordUpdates[x].reference} === ${action.updateRecords.reference}`);
        //console.log(`${JSON.stringify(clone.dataRecordUpdates[x].identifiers)} === ${JSON.stringify(action.updateRecords.identifiers)}`);

        if (clone.dataRecordUpdates[x].apiTransactionId = action.updateRecords.apiTransactionId) {
          //console.log('DATA | Found API Transaction Match');

          //Update with the latest status
          clone.dataRecordUpdates[x].apiCompleted = action.updateRecords.apiCompleted;
          clone.dataRecordUpdates[x].apiInprogress = action.updateRecords.apiInprogress;
          clone.dataRecordUpdates[x].apiErrors = action.updateRecords.apiErrors;
          clone.dataRecordUpdates[x].apiStatus = action.updateRecords.apiStatus;

          foundMatch = true;
        }








        //Old code (we don't want to have to match on every value - use the ????)
        if (clone.dataRecordUpdates[x].state === action.updateRecords.state &&
            clone.dataRecordUpdates[x].reference === action.updateRecords.reference &&
            JSON.stringify(clone.dataRecordUpdates[x].identifiers) === JSON.stringify(action.updateRecords.identifiers)
        ) {
          // Found match, update
          //console.log('data | found match');
          clone.dataRecordUpdates[x].value = action.updateRecords.value;
          clone.dataRecordUpdates[x].apiTransactionId = action.updateRecords.apiTransactionId;

          //Update with the latest status
          clone.dataRecordUpdates[x].apiCompleted = true; //action.updateRecords.apiCompleted;
          clone.dataRecordUpdates[x].apiInprogress = action.updateRecords.apiInprogress;
          clone.dataRecordUpdates[x].apiErrors = action.updateRecords.apiErrors;
          clone.dataRecordUpdates[x].apiStatus = action.updateRecords.apiStatus;

          foundMatch = true;
        }
      }
      if (foundMatch === false) {
        // No match, insert
        //console.log('data | not found - insert');
        clone.dataRecordUpdates.push(action.updateRecords);
      }

      //console.log(`CLONE AFTER SYNC`);
      //console.log(clone);

      return clone;
    }





    case 'SYNC_HISTORY_USER':
    {
      const clone = Object.assign({}, state);


      return clone;
    }

    case 'SYNC_HISTORY_BUSINESS':
    {
      const clone = Object.assign({}, state);


      return clone;
    }

    case 'SYNC_HISTORY_SOFTWARE':
    {
      const clone = Object.assign({}, state);


      return clone;
    }

    case 'SYNC_TIMESTAMP_USER':
    {
      const clone = Object.assign({}, state);


      return clone;
    }

    case 'SYNC_TIMESTAMP_BUSINESS':
    {
      const clone = Object.assign({}, state);


      return clone;
    }

    case 'SYNC_TIMESTAMP_SOFTWARE':
    {
      const clone = Object.assign({}, state);


      return clone;
    }


    default:
      return state;
  }
};




export default SyncReducer;
