import _ from 'lodash';

const initialState = {
  version: '',
  systemMessages: [],     //Time based system messages for all users (system updates, system health)



};

const resetState = {
  version: '',
  systemMessages: [],     //Time based system messages for all users (system updates, system health)



};


const AppReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'CLEAR_STATE_APP':
    {
      return resetState;
    }

    case 'RESET_STATE_APP':
    {
      const clone = Object.assign({}, state);

      let mergedStateFromInitial = _.merge(initialState, clone);  

      return mergedStateFromInitial;
    }

    case 'UPDATE_APP_VERSION':
    {
      const clone = Object.assign({}, state);
      clone.version = '1.0.0';
      return clone;
    }

    default:
      return state;
  }
};

export default AppReducer;
